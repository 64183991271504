import React from 'react';
import { useTranslation } from "react-i18next";
import './FacialInstructions.sass';

import img_dica_front from "../../images/ilustra-dicaDocumento.png";
import img_dica_back from "../../images/ilustra-dicaDocumento-verso.png";
import { Box } from '@mui/material';

interface Props {
    handleCloseInstructions: () => void;
    isVerse?: boolean;
    children: React.ReactNode;
}

const DocumentInstructions = ({ handleCloseInstructions, isVerse = false, children }: Props) => {
    const { t } = useTranslation();

    const instructionsTitle = isVerse
        ? t("capture_document.verse_title")
        : t("capture_document.front_title");

    const instructionsImage = isVerse ? img_dica_back : img_dica_front;

    return (
        <div className="instructions-content">
          <Box onClick={() => {
            document.querySelector('.instructions-content')?.scrollTo({ top: 0, behavior: 'smooth' });
            handleCloseInstructions();
            }
          }>
            {children}
          </Box>
            <img
                className="no-svg-tip"
                src={instructionsImage}
                alt={t("capture_document.instructions")}
            />
            <ul>
                <li className="instruction-item">
                    <span className="number-instruction">1</span>
                    <div className="instruction-text">
                        <p className="instruction-title">{t("step.validate_document")}</p>
                        <p>{t("capture_document.tips.tip_1")}</p>
                    </div>
                </li>
                <li className="instruction-item">
                    <span className="number-instruction">2</span>
                    <div className="instruction-text">
                        <p className="instruction-title">{t("step.prepare_surface")}</p>
                        <p>{t("capture_document.tips.tip_2")}</p>
                    </div>
                </li>
                <li className="instruction-item">
                    <span className="number-instruction">3</span>
                    <div className="instruction-text">
                        <p className="instruction-title">{t("step.center_frame")}</p>
                        <p>{t("capture_document.tips.tip_3")}</p>
                    </div>
                </li>
                <li className="instruction-item">
                    <span className="number-instruction">4</span>
                    <div className="instruction-text">
                        <p className="instruction-title">{t("step.capture")}</p>
                        <p>{t("capture_document.tips.tip_4")}</p>
                    </div>
                </li>
                <li className="instruction-item">
                    <span className="number-instruction">5</span>
                    <div className="instruction-text">
                        <p className="instruction-title">{t("step.prepare_surface")}</p>
                        <p>{t("capture_document.tips.tip_5")}</p>
                    </div>
                </li>
                <li className="instruction-item">
                    <span className="number-instruction">6</span>
                    <div className="instruction-text">
                        <p className="instruction-title">{t("step.center_frame")}</p>
                        <p>{t("capture_document.tips.tip_6")}</p>
                    </div>
                </li>
            </ul>
            <div className="button-container">
                <button className="retry-button" onClick={() => {
                  document.querySelector('.instructions-content')?.scrollTo({ top: 0, behavior: 'smooth' });
                  handleCloseInstructions()
                }
                }>
                    {t("capture_document.buttons.retry_photo")}
                </button>
            </div>
            {/* <footer className="footer">
                <img
                    src="/logo_bepass_default.svg"
                    alt="Bepass Logo"
                    className="footer-logo"
                />
            </footer> */}
        </div>
    );
};

export default DocumentInstructions;