import Welcome from './Pages/Welcome';
import Exemplo from './Pages/Exemplo';
import Signup from './Pages/Signup/Signup';
import CaptureDocumentMobile from './components/CaptureDocumentMobile/CaptureDocumentMobile';
import React, {useEffect, useRef, useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SelfieCapture from "./Pages/SelfieCapture";
import MobileHandler from "./Pages/MobileHandler";
import SuccessHandler from './Pages/SuccessHandler';
import { Box } from '@mui/material';
import './App.sass'
import bepass_white_logo from "./images/logoCompleto.png";
import { BASE } from './basedata/constants';
import DocumentHandler from './Pages/DocumentHandler';
import { signInAnonymously } from 'firebase/auth';
import { auth } from './basedata/firebase';
import Validating from './Pages/Validating';
import Loader from './components/Loader/Loader';
import { apiUser } from './basedata/apiCall';
import ErrorHandler from './Pages/ErrorHandler';
import { useTranslation } from 'react-i18next';


const App = () => {

  const [tempCredentials, setTempCredentials] = useState<any>('')
  const [loading, setLoading] = useState(true)
  const [userFlow, setuserFlow] = useState(false)
  
  const getCredentials = async () => {

    try{
      const userCredential:any = await signInAnonymously(auth)
  
      sessionStorage.setItem('user-key', JSON.stringify({user_key: userCredential?.user?.accessToken, tenant_id: BASE.company.tenant}))
      const sessionItem:any = window.sessionStorage.getItem('user-key')
    
      setTempCredentials(userCredential.user?.accessToken)
    }catch(e){
      return false
    }
    return true
  }

  const getUserFlow = async (userToken:any) => {

    try{

      const _response = await apiUser.post(`/biometry/flow/user-jorney`, {flowId: userToken})

      if(_response.data.status === 'success'){
        sessionStorage.setItem('user_history', _response.data.message)
        sessionStorage.setItem('get_flow_index', 'true')
        
        setuserFlow(true)
        
        const _current_location = window.location.pathname
        if(_response.data.message === 'selfie'){
          if(!/selfie/.test(_current_location)){
            window.location.href = 'selfie'
          }
        }else if(_response.data.message === 'document_front'){
          if(!/mobile-document/.test(_current_location)){
            window.location.href = 'mobile-document'
          }
        }else if(_response.data.message === 'document_back'){
          if(!/mobile-document/.test(_current_location)){
            window.location.href = 'mobile-document?step=document_back'
          }
        }else if(_response.data.message === 'done'){
          if(!/success/.test(_current_location)){
            window.location.href = 'success'
          }
        }
        setLoading(false)
      }else{
        setLoading(false)
      }

    }catch(e){
      setTimeout(() => {
        window.location.reload()
      }, 3000);
    }
    return true
  }

  useEffect(() => {
    getCredentials();
  }, [])

  useEffect(() => {

    const _flow_index = sessionStorage.getItem('get_flow_index')

    if(!_flow_index){
    
    const params = new URLSearchParams(window.location.search)

    let currentToken:any = params.get('token');
    let currentOrc:any = params.get('orc');

    if(!currentToken){
      currentToken = sessionStorage.getItem('token')
      currentOrc = sessionStorage.getItem('orc')  
      if(!currentToken){
        return setLoading(false)
      }
    }else{
      sessionStorage.clear()
      sessionStorage.setItem('token', currentToken )
      sessionStorage.setItem('orc', currentOrc )  
    }

    const _has_history = sessionStorage.getItem('user_history')

    if(_has_history){
      sessionStorage.setItem('get_flow_index', 'true')
      const _current_location = window.location.pathname
      if(_has_history === 'selfie'){
        if(!/selfie/.test(_current_location)){
          window.location.href = 'selfie'
        }
      }else if(_has_history === 'document_front'){
        if(!/mobile-document/.test(_current_location)){
          window.location.href = 'mobile-document'
        }
      }else if(_has_history === 'document_back'){
        if(!/mobile-document/.test(_current_location)){
          window.location.href = 'mobile-document?step=document_back'
        }
      }else if(_has_history === 'done'){
        if(!/success/.test(_current_location)){
          window.location.href = 'success'
        }
      }
      setLoading(false)
    }else{
      getUserFlow(currentToken);
    }
  }else{
    return setLoading(false)
  }
  }, [userFlow])


  const { i18n } = useTranslation();

  useEffect(() => {
    const lingua:any = window.sessionStorage.getItem('language')
    i18n.changeLanguage(lingua);
  }, [])
  
  return (
      loading ? (
        <div className="loader">
        <div className="spinner">
        </div>
        </div>
      ):(
          
          <Router>
            <Routes>
              <Route path="/" element={
                <Box>
                <Box className="main-wrapper" style={{ backgroundImage: `url(${BASE.company.background_image})` }}>
                <Box className="onboarding-content-wrapper">
                  <img className="logo" src={BASE.company.logo} alt={BASE.company.name} />
                  <Welcome />
                </Box>
                </Box>
                <footer className="footer">
                  <img src={BASE.company.bepassLogo} alt="Bepass Logo" className="footer-logo" />
                </footer>
                </Box>
              } />
              <Route path="/signup" element={
                <Box>
                <Box className="main-wrapper" style={{ backgroundImage: `url(${BASE.company.background_image})` }}>
                <Box className="onboarding-content-wrapper">
                  <Signup />
                </Box>
                </Box>
                <footer className="footer">
                  <img src={BASE.company.bepassLogo} alt="Bepass Logo" className="footer-logo" />
                </footer>
                </Box>
              } />
              <Route path="/mobile-handler" element={
                <Box>
                <Box className="main-wrapper" style={{ backgroundImage: `url(${BASE.company.background_image})` }}>
                <Box className="onboarding-content-wrapper">
                  <img className="logo" src={BASE.company.logo} alt={BASE.company.name} />
                  <MobileHandler />
                </Box>
                </Box>
                <footer className="footer">
                  <img src={BASE.company.bepassLogo} alt="Bepass Logo" className="footer-logo" />
                </footer>
                </Box>
              }/>
              <Route
              path="/selfie"
              element={
                <SelfieCapture/>
              }/>
    
            <Route path="/document-handler" element={
              <Box className="onboarding-content-wrapper">
                <img className="logo" src={BASE.company.logo} alt={BASE.company.name} />
                <DocumentHandler/>
              </Box>
            } />
                  
              <Route path="/mobile-document" element={<CaptureDocumentMobile />} />
              <Route path="/success" element={<SuccessHandler/>} />
              <Route path="/waiting" element={<ErrorHandler/>} />
    
              <Route path="/validating" element={
                <Box className="onboarding-content-wrapper">
                  <img className="logo" src={BASE.company.logo} alt={BASE.company.name} />
                  <Validating />
                </Box>
              } />
    
              <Route path="/css-colors" element={<Exemplo />} />
            </Routes>
          </Router>
        )
  );
};


export default App;