import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import bepass_white_logo from "../../images/logoCompleto.png";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useRegisterData } from './SignupStepper';
import { Box, FormControl, Input, InputLabel } from '@mui/material';
import { apiUser } from '../../basedata/apiCall';
import { BASE } from '../../basedata/constants';

import validator from 'validator';

interface FormStepperInterface {
  onBack: () => void;
}

const SignupPartTwo = ({ onBack}:FormStepperInterface) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [formData, setFormData] = useState({
        phone: '',
        gender: '',
        email: ''
    });

  const {handleRegisterFormData} = useRegisterData()

  const {
    register,
    handleSubmit,
    setError,
    reset,
    getValues,
    watch,
    formState: { errors },
    clearErrors
  } = useForm()

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isMinor, setIsMinor] = useState(true);

    const isFormValid = watch('phone') && watch('email')

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { id, value } = e.target
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }))

        // setErrors((prevErrors) => ({
        //     ...prevErrors,
        //     [id]: value ? '' : t('signup.errors.required'),
        // }))
    }

    // const formSubmitter = (data:any) => {

    //   console.log('piutaria')
    //   console.log(data)
    //   return false
      
    // };

    const formSubmitter = async (data:any) => {
      

      const sessionOne = sessionStorage.getItem("heartPartOne");
      const partOneData = sessionOne ?JSON.parse(sessionOne) : {};
      const fullData = { ...partOneData, ...data };


      if(!data.phone){
        return setError('phone', {
           type: 'manual',
           message: 'Você precisa preencher ao menos 1 campo!'
         })
       }

       if(!validator.isEmail(data.email)){
        return setError('email', {
           type: 'manual',
           message: 'Você precisa preencher ao menos 1 campo!'
         })
       }
    
       
        const properName = fullData.name.replace(/^\s+|\s+$/g, '').split(' ')
        const dateFormate = fullData.birthdate.split('/')

        const _user_register = await apiUser.post('/user',{
          firstName: properName.shift(),
          externalId: `${fullData.document.replace(/^\s+|\s+$/g, '')}_${Math.floor(Math.random() * 291)}`,
          lastName: properName.join(' '),
          gender: '',
          document: fullData.document.replace(/^\s+|\s+$/g, ''),
          document_type: '',
          email: fullData.email.replace(/^\s+|\s+$/g, ''),
          phoneNumber: fullData.phone
            .replace(/"/g, '')
            .replace(/'/g, '')
            .replace(/\(|\)/g, ''),
          birthDate:
            dateFormate[2] + '-' + dateFormate[1] + '-' + dateFormate[0],
          nationality: '',
          originCompanyId: BASE.company.id,
          function: fullData.parent_name || '',
          sector: fullData.parent_document || '',
        })

        sessionStorage.setItem('token', _user_register?.data?.sup)
        window.location.href = `/validating?token=${_user_register.data.sup}`
        return false
        
        // const newErrors = {
        //     phone: formData.phone ? '' : t('signup.errors.required'),
        //     date: formData.birthdate ? '' : t('signup.errors.required'),
        //     email: formData.email ? '' : t('signup.errors.required'),
        //     // gender: formData.gender && formData.gender !== 'start' ? '' : t('signup.errors.required'),
        // }

        // data.gogo = 'rola'
        // handleRegisterFormData(data)
        // const hasErrors = Object.values(newErrors).some((error) => error)
        // if (!hasErrors) {
        //     // const savedData = localStorage.getItem("signupPartOne");
        //     // const partOneData = savedData ?JSON.parse(savedData) : {};
        //     // const fullData = { ...partOneData, ...formData };

        //     console.log(fullData);

        //     localStorage.setItem("signupComplete", JSON.stringify(fullData));

        //     navigate("/mobile-handler");
        // }
    }

    // const onNext = () => {
    //   window.location.href = '/mobile-handler'
    // }
    useEffect(() => {

      const sessionOne = sessionStorage.getItem("heartPartOne");
      const partOneData = sessionOne ?JSON.parse(sessionOne) : {};

      if (partOneData.birthdate) {
        const [day, month, year] = partOneData.birthdate.split('/').map(Number);
        const birthDate = new Date(year, month - 1, day);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
          age--;
        }

        if (age > 16) {
          setIsMinor(false)
        }else{
          setIsMinor(true)
        }
      }

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const validationCheck = (domEvent:any, domField:any) => {
      let e = domEvent.target.value

      if(e.trim() === ''){
        setTermsAccepted(false)
      }else if(domField === 'phone' &&  getValues('email').trim() === ''){
        setTermsAccepted(false)
      }else if(domField === 'email' && (getValues('phone').trim() === '')){
        setTermsAccepted(false)
      }else{
        setTermsAccepted(true)
      }
    }

    const minorValidationCheck = (domEvent:any, domField:any) => {
      let e = domEvent.target.value
      if(e.trim() === ''){
        setMinorCheck(false)
      }else if(domField === 'parent_name' &&  getValues('parent_document').trim() === ''){
        setMinorCheck(false)
      }else if(domField === 'parent_document' && (getValues('parent_name').trim() === '')){
        setMinorCheck(false)
      }else{
        setMinorCheck(true)
      }
    }

      const [accepted, setAccepted] = useState(false);
      const [minorCheck, setMinorCheck] = useState(false);
    
      const handleAcceptTerms = () => {
        setAccepted(!accepted);
      };
    

    return (
      <>
        <form className="signup-form bepass-form" onSubmit={handleSubmit(formSubmitter)} noValidate>
{isMinor ? (
<Box>
<h1 className="bepass-title">{t('signup.messages.minor_message_title')}</h1>

      <p className='bepass-text-default'>
          {t('signup.messages.minor_message')}
      </p>
<FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              Documento do responsável
            </InputLabel>
            <Input
              type="text" 
              id="parent_document" 
              // {...register('parent_document')}

              {...register('parent_document', {
                validate: (value) => {
                    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
                    return cpfRegex.test(value) || t('signup.errors.invalid_cpf');
                }
              })}

              onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, '');
                  value = value.replace(/(\d{3})(\d)/, '$1.$2');
                  value = value.replace(/(\d{3})(\d)/, '$1.$2');
                  value = value.replace(/(\d{3})(\d{1,14})/, '$1-$2');
                  e.target.value = value.slice(0, 14);
                  minorValidationCheck(e, 'parent_document')
              }}


              // placeholder={t('signup.placeholders.parent_document')}
              // value={formData.parent_document}
              placeholder="Documento do responsável"
            />
            </FormControl>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
            Nome do responsável
            </InputLabel>
            <Input
              type="text" 
              id="parent_name" 
              {...register('parent_name')}
              onChange={(e) => {minorValidationCheck(e, 'parent_name')}}
              // placeholder={t('signup.placeholders.parent_document')}
              placeholder="Nome do responsável"
            />
          </FormControl>

          {errors.email && (
            <span className="label-error">
              Você precisa informar um e-mail válido.
            </span>
          )}

          {errors.phone && (
            <span className="label-error">
              Você precisa informar um telefone válido.
            </span>
          )}

<div className="terms-container">
      <input
        type="checkbox"
        id="accept-terms"
        checked={accepted}
        onChange={handleAcceptTerms}
      />
     
      <label  className="accept-terms" htmlFor="accept-terms">
        {t("signup.messages.minor_confirm_message_start")} 
        <a 
          href="/bepass_consentment.pdf" rel="noreferrer noopener" target="_blank"
          className="bold-link"
        >{t("signup.messages.minor_confirm_message_highlight")} </a>
        {t("signup.messages.minor_confirm_message_end")} 
      </label>
    </div>
          <button 
                type="button" 
                className="continue-button" 
                onClick={() => {
                  setIsMinor(false)
                }}
                disabled={!accepted && !minorCheck}
            >
                {/* {t('signup.buttons.finish')} */}
                Próximo
            </button>

</Box>
):(
<Box>
<p className='bepass-text-default'>
          {t('signup.messages.register_message')}
      </p>
<FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              Celular
            </InputLabel>
            <Input
              type="text" 
              id="phone" 
              {...register('phone')}
              placeholder={t('signup.placeholders.phone')}
              value={formData.phone}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, ''); // Remove non-digits
              
                const maskedValue = value
                  .replace(/^(\d{2})(\d)/, '($1) $2') // Add (99) at the beginning

              
                setFormData((prevData) => ({
                  ...prevData,
                  phone: maskedValue,
                }));
                validationCheck(e, 'phone')
              }}
            />
            </FormControl>

          <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              E-mail
            </InputLabel>
            <Input
              type="email" 
              id="email" 
              {...register('email')}
              onChange={(e) => {validationCheck(e, 'email')}}
              placeholder={t('signup.placeholders.email')}
            />
          </FormControl>

          {errors.email && (
            <span className="label-error">
              Você precisa informar um e-mail válido.
            </span>
          )}

          {errors.phone && (
            <span className="label-error">
              Você precisa informar um telefone válido.
            </span>
          )}
          <button 
                type="submit" 
                className="continue-button" 
                // onClick={onNext}
                disabled={!termsAccepted}
            >
                {t('signup.buttons.finish')}
            </button>
  </Box>
)}
            

            <div className="back-button" onClick={onBack}>
                <div className="icon-container">
                    <ArrowBackIcon className="arrow-icon" />
                </div>
                <span className="text">{t('signup.navigation.back_text')}</span>
            </div>

        </form>
        {isMobile && ( 
          <></>
            // <div className="footer">
            //     <img src={bepass_white_logo} alt="Bepass Logo" className="footer-logo" />
            // </div>
        )}
    </>
    );
};

export default SignupPartTwo