import React, {useEffect, useState} from 'react'
import FacialInstructions from "../Intructions/FacialInstructions";
import MobilePagination from "../MobilePagination/MobilePagination";
import {useTranslation} from "react-i18next";
import SelfieSupport from "../SelfieSupport/SelfieSupport";
import './SelfieInvalid.sass'

interface Props {
    selfieAttempts: number,
    handleRetryInstructions: () => void,
    selfieBase64?: string
}

const SelfieInvalid = ({selfieAttempts, handleRetryInstructions, selfieBase64}: Props) => {
    const {t} = useTranslation();
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
    const [activeStep, setActiveStep] = useState(1);


    const togglePanel = () => {
      console.log('handled')
    };


    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="container-selfie-invalid">
            {isMobile && selfieAttempts < 2 && (
                <div>
                    <MobilePagination
                        activeStep={activeStep}
                    />
                </div>
            )}
            {selfieAttempts < 2 && (
                <img className='logo' src="/client_logo.svg" alt='logo'/>
            )}
            {selfieAttempts === 0 ? (
                <FacialInstructions togglePanel={togglePanel} handleCloseInstructions={handleRetryInstructions}>
                    <div className='selfie-invalid-content'>
                        <h1 className="instruction-title">{t("invalid_selfie.first_try.title")}</h1>
                        <p>{t("invalid_selfie.first_try.quote_1")}</p>
                        <p>{t("invalid_selfie.first_try.quote_2")}</p>
                    </div>
                </FacialInstructions>
            ) : selfieAttempts === 1 ? (
                <FacialInstructions togglePanel={togglePanel} handleCloseInstructions={handleRetryInstructions}>
                    <h1 className="instruction-title">{t("invalid_selfie.second_try.title")}</h1>
                    <p>{t("invalid_selfie.second_try.quote_1")}</p>
                    <p>{t("invalid_selfie.second_try.quote_2")}</p>
                </FacialInstructions>
            ) : selfieAttempts > 1 ? (
                <SelfieSupport selfieBase64={selfieBase64} onResponse={handleRetryInstructions}/>
            ) : null
            }
        </div>
    )
}
export default SelfieInvalid
