import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./documentHandler.sass";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import rgIcon from './../images/document_type.svg'
import cnhIcon from './../images/document_type_cnh.svg'

const DocumentHandler = () => {
  const { t } = useTranslation(); 
  const navigate = useNavigate();


  const callbackHandler = () => {
    window.location.href = '/mobile-document'
  }
  return (
    <div className="welcome-card">
    <h1 className="bepass-title">Documento</h1>
    <p className="bepass-text-default">
    Como prefere prosseguir?<br/>
    Você pode utilizar a sua <strong>CNH</strong> ou o seu <strong>RG</strong> que <strong>contenha o CPF</strong>.
    </p>

    <ul className="document-type-select">
      <li onClick={() => callbackHandler()}>
        <i>
          <img src={rgIcon} alt="icone documento"/>
        </i>
        <Box className="type-select-description">
          <h2 className="bepass-title">RG</h2>
          <p className="bepass-text-label">Documento de Identidade</p>
        </Box>
      </li>

      <li onClick={() => callbackHandler()}>
        <i>
          <img src={cnhIcon} alt="icone documento"/>
        </i>
        <Box className="type-select-description">
          <h2 className="bepass-title">CNH</h2>
          <p className="bepass-text-label">Carteira nacional de habilitação</p>
        </Box>
      </li>


      <li onClick={() => callbackHandler()}>
        <i>
          <img src={cnhIcon} alt="icone documento"/>
        </i>
        <Box className="type-select-description">
          <h2 className="bepass-title">CNH Digital</h2>
          <p className="bepass-text-label">Inportar carteira nacional de habilitação</p>
        </Box>
      </li>
    </ul>
  </div>
  );
};

export default DocumentHandler;

