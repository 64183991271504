import React, {ReactNode} from 'react'
import {useTranslation} from "react-i18next";
import './FacialInstructions.sass'
import { Box } from '@mui/material';

interface Props {
    handleCloseInstructions: () => void;
    children: React.ReactNode;
    togglePanel: () => void;
}

const FacialInstructions = ({handleCloseInstructions, togglePanel, children}:Props) => {
    const { t } = useTranslation();

    return (
        <div className="instructions-content">
            <Box onClick={() => {
              // togglePanel();
            }}
            onTouchEnd={() => {
              togglePanel();
              document.querySelector('.instructions-content')?.scrollTo({ top: 0, behavior: 'smooth' });}
            }
            >
            {children}
          </Box>
            <img className="no-svg-tip" src='/tip-image.png' alt={t("capture_face.instructions")}/>
            <ul>
                <li className="instruction-item">
                    <div className="instruction-text">
                        <div className='instruction-header'>
                            <span className="number-instruction">1</span>
                            <p className="instruction-title">{t("facial_step.step_1")}</p>
                        </div>
                        <p>{t("facial_step.tip_1")}</p>
                    </div>
                </li>
                <li className="instruction-item">
                    <div className="instruction-text">
                        <div className='instruction-header'>
                            <span className="number-instruction">2</span>
                            <p className="instruction-title">{t("facial_step.step_2")}</p>
                        </div>
                        <p>{t("facial_step.tip_2")}</p>
                    </div>
                </li>
                <li className="instruction-item">
                    <div className="instruction-text">
                        <div className='instruction-header'>
                            <span className="number-instruction">3</span>
                            <p className="instruction-title">{t("facial_step.step_3")}</p>
                        </div>
                        <p>{t("facial_step.tip_3")}</p>
                    </div>
                </li>
                <li className="instruction-item">
                    <div className="instruction-text">
                        <div className='instruction-header'>
                            <span className="number-instruction">4</span>
                            <p className="instruction-title">{t("facial_step.step_4")}</p>
                        </div>
                        <p>{t("facial_step.tip_4")}</p>
                    </div>
                </li>
                <li className="instruction-item">
                    <div className="instruction-text">
                        <div className='instruction-header'>
                            <span className="number-instruction">5</span>
                            <p className="instruction-title">{t("facial_step.step_5")}</p>
                        </div>
                        <p>{t("facial_step.tip_5")}</p>
                    </div>
                </li>
                <li className="instruction-item">
                    <div className="instruction-text">
                        <div className='instruction-header'>
                            <span className="number-instruction">6</span>
                            <p className="instruction-title">{t("facial_step.step_6")}</p>
                        </div>
                        <p>{t("facial_step.tip_6")}</p>
                    </div>
                </li>
            </ul>
            <div className="button-container">
                <button className="retry-button" onClick={() => {
                  document.querySelector('.instructions-content')?.scrollTo({ top: 0, behavior: 'smooth' });
                  handleCloseInstructions()
                }}>
                    {t("capture_face.take_photo")}
                </button>
            </div>
            {/* <footer className="footer">
                <img
                    src="/logo_bepass_default.svg"
                    alt="Bepass Logo"
                    className="footer-logo"
                />
            </footer> */}
        </div>
    )
}
export default FacialInstructions
