import React from 'react'

const FrameSvg = () => {
    return (
        <svg width="350" height="440" xmlns="http://www.w3.org/2000/svg" className='custom-svg'>
            <line x1="15" y1="20" x2="75" y2="20" stroke="current-color" strokeWidth="4"/>
            <line x1="15" y1="20" x2="15" y2="120" stroke="current-color" strokeWidth="4"/>

            <line x1="275" y1="20" x2="335" y2="20" stroke="current-color" strokeWidth="4"/>
            <line x1="335" y1="20" x2="335" y2="120" stroke="current-color" strokeWidth="4"/>

            <line x1="15" y1="380" x2="15" y2="438" stroke="current-color" strokeWidth="4"/>
            <line x1="15" y1="438" x2="75" y2="438" stroke="current-color" strokeWidth="4"/>

            <line x1="275" y1="438" x2="335" y2="438" stroke="current-color" strokeWidth="4"/>
            <line x1="335" y1="380" x2="335" y2="438" stroke="current-color" strokeWidth="4"/>
        </svg>
    )
}
export default FrameSvg
