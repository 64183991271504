import React, { useEffect } from 'react';
import './ModalInfo.sass'

interface Props {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const ModalInfo = ({ isOpen, onClose, children }: Props) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const modalContent = document.querySelector('.modal-content');
            if (isOpen && modalContent && !modalContent.contains(event.target as Node)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                {children}
            </div>
        </div>
    );
};

export default ModalInfo;