export const BASE = {
  company: {
    logo: "https://storage.googleapis.com/companies-assets/corinthians/corinthians_202344125840.png",
    background_image: "https://storage.googleapis.com/companies-assets/corinthians/onboarding-bg-corinthians.jpg",
    primaryColor: "#444444",
    secondaryColor: "#fe0100",
    tertiaryColor: "#541b10",
    copyrightLogo: "default",
    backgroundColor: ["#000000", "#000000"],
    tenant: 'corinthians-1tzgr',
    name: 'Corinthians',
    bepassLogo: "https://storage.googleapis.com/companies-assets/bepass/bepass_white_logo.png",
    id: '883a5c84-345e-44b7-9286-454f9ee5a93a',
    dis: 0.87
  }
}