import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import ModalInfo from "../Modal/ModalInfo";

interface Props {
    documentBase64?: string,
    onResponse: () => void
}

const DocumentSupport = ({documentBase64, onResponse}: Props) => {
    const { t } = useTranslation();
    const [isSupportModalOpen, setIsSupportModalOpen] = useState(false)
    const [loadingProgress, setLoadingProgress] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const closeModal = () => {
        setIsSupportModalOpen(false);
        onResponse();
    };

    const simulateLoading = () => {
        setIsLoading(true);
        setLoadingProgress(0);
        const interval = setInterval(() => {
            setLoadingProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(interval);
                    // setIsLoading(false);
                    // setIsSupportModalOpen(true)
                }
                return prev + 1;
            });
        }, 900);
    };

    useEffect(() => {
        simulateLoading();
    },[])

    return (
        <div className="container-selfie-success">
            <img src={documentBase64} alt="selfie" className="selfie-taken"/>
            {isLoading && (
                <div className="loader-container">
                    <div
                        className="loader-bar"
                        style={{width: `${loadingProgress}%`}}
                    ></div>
                    <span className="loader-percentage">{loadingProgress}%</span>
                </div>
            )}
            <ModalInfo isOpen={isSupportModalOpen} onClose={closeModal}>
                <h1>{t("support_message.title")}</h1>
                <p>{t("support_message.quote_1_document")}</p>
                <p>{t("support_message.quote_2")}</p>
                <p><b>E-mail: </b>{t("support_message.email")}</p>
                <p><b>Whatsapp: </b>{t("support_message.whatsapp")}</p>
                <p><b>{t("support_message.telephone_key")}: </b>{t("support_message.telephone_value")}</p>
            </ModalInfo>
        </div>
    );
};
export default DocumentSupport;
