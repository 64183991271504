import React, {  useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import './Signup.sass';
import SignupStepper from "./SignupStepper";
import MobilePagination from "../../components/MobilePagination/MobilePagination";
import { BASE } from "../../basedata/constants";

const Signup = () => {
    const { t } = useTranslation();

    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5", "Step 6"];

    const nextStep = () => {
        if (activeStep < steps.length - 1) {
            setActiveStep((prevStep) => prevStep + 1);
        }
    };

    const prevStep = () => {
        if (activeStep > 0) {
            setActiveStep((prevStep) => prevStep - 1);
        }
    };

    return (
      <div className="singup-card">
          {isMobile ? (
              <>
              <div className="mobile-pagination">
                  <MobilePagination
                      activeStep={activeStep} 
                  />
              </div>
              <img className="logo" src={BASE.company.logo} alt={BASE.company.name} />
              </>
          ):(
          <img className="logo" src={BASE.company.logo} alt={BASE.company.name} />
          )}
          <SignupStepper
              activeStep={activeStep}
              nextStep={nextStep}
              prevStep={prevStep}
          />
      </div>
    );
};

export default Signup;