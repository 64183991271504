import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./DocumentCapture.sass";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from 'react-device-detect'

interface Props {
  onResponse: (response: { document: string; step: string }) => void;
  onError: (error: any) => void;
  isCameraOpen: boolean;
  isInstructionsOpen: boolean;
}

const DocumentCapture = ({ onResponse, onError, isCameraOpen, isInstructionsOpen }: Props) => {
  const { t } = useTranslation();

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [base64Image, setBase64Image] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const [isFrameVisible, setIsFrameVisible] = useState(true);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "environment",
        },
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error("Erro ao acessar a câmera:", error);
      onError(error);
    }
  };

  const stopCamera = () => {
    if (videoRef.current?.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  const drawCanvasImage = () => {
    if (!canvasRef.current || !videoRef.current) return;

    const video = videoRef.current;
    const canvas = canvasRef.current;

    canvas.width = video.videoWidth || 0;
    canvas.height = video.videoHeight || 0;

    const context = canvas.getContext("2d", { willReadFrequently: true });
    if (context) {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
    }
  };

  const captureDocument = () => {
    if (!videoRef.current || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const video = videoRef.current;

    canvas.width = video.videoWidth || 0;
    canvas.height = video.videoHeight || 0;

    const context = canvas.getContext("2d");
    if (context) {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      setTimeout(() => {
        const base64 = canvas.toDataURL("image/png");
        setBase64Image(base64);
        onResponse({ document: base64, step: "done" });
      }, 1000);
    }
  };

  const simulateLoading = () => {
    setIsLoading(true);
    setLoadingProgress(0);
    const interval = setInterval(() => {
      setLoadingProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
        }
        return prev + 10;
      });
    }, 900);
  };

  useEffect(() => {

    const params = new URLSearchParams(window.location.search)
    let currentToken = params.get('token'),
    currentCompany = params.get('orc')

    if(currentToken){
      sessionStorage.setItem('token', `${currentToken}`)
      sessionStorage.setItem('originCompany', `${currentCompany}`)
    }else{
      currentToken = window.sessionStorage.getItem('token')
    }

    
    if(!isMobile){
      window.location.href = `/mobile-handler?token=${currentToken}`
    }

    const alreadly_done = sessionStorage.getItem('success_flow') || null
    if(alreadly_done){
      // window.location.href = '/success'
    }
    sessionStorage.removeItem('selfie_retries')
    const initializeCamera = async () => {
      setIsLoading(true);
      await startCamera();
      setIsLoading(false);
    };

    initializeCamera();

    return () => {
      stopCamera();
    };
  }, []);

  return (
    <div className="camera-capture-container">
      {!base64Image ? (
        <>
          <div className="video-container">
            <video ref={videoRef} autoPlay playsInline className="video-preview" />
            {isCameraOpen && !isInstructionsOpen && (
              <>
              {/* <div className="clip-document"></div> */}
                {/* <div className="frame frame-top-left"></div>
                <div className="frame frame-top-right"></div>
                <div className="frame frame-bottom-left"></div>
                <div className="frame frame-bottom-right"></div> */}
              </>
            )}
          </div>


          <canvas ref={canvasRef} style={{ display: "none" }} />

          {isLoading && (
            <div className="loader-container">
              <div
                className="loader-bar"
                style={{ width: `${loadingProgress}%` }}
              ></div>
              <span className="loader-percentage">{loadingProgress}%</span>
            </div>
          )}

          <button
            className="capture-button"
            onClick={captureDocument}
            disabled={isLoading}
          >
            <CameraAltIcon className="camera-icon" />
            {t("capture_document.buttons.retry_photo")}
          </button>
        </>
      ) : (
        <div className="captured-image-container">
          <img
            src={base64Image || ""}
            alt="Documento Capturado"
            className="captured-image"
          />
        </div>
      )}
    </div>
  );
};

export default DocumentCapture;