import React, { useEffect, useState } from 'react';
import MobilePagination from "../MobilePagination/MobilePagination";
import { useTranslation } from "react-i18next";
import SelfieSupport from "../SelfieSupport/SelfieSupport";
import './DocumentInvalid.sass';
import DocumentInstructions from '../Intructions/DocumentInstructions';
import DocumentSupport from '../DocumentSupport/DocumentSupport';

interface Props {
    documentAttempts: number;
    handleRetryInstructions: () => void;
    documentBase64?: string;
    isVerse: boolean;
}

const DocumentInvalid = ({
    documentAttempts,
    handleRetryInstructions,
    documentBase64,
    isVerse
}: Props) => {
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
    const [activeStep, setActiveStep] = useState(1);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const getMessages = () => {
        if (isVerse) {
            if (documentAttempts === 0) {
                return {
                    title: t("capture_document.verse_messages.first_try.title"),
                    message1: t("capture_document.verse_messages.first_try.message_1"),
                    message2: t("capture_document.verse_messages.first_try.message_2"),
                    message3: t("capture_document.verse_messages.first_try.message_3")
                };
            }
            if (documentAttempts === 1) {
                return {
                    title: t("capture_document.verse_messages.second_try.title"),
                    message1: t("capture_document.verse_messages.second_try.message_1"),
                    message2: t("capture_document.verse_messages.second_try.message_2"),
                    message3: t("capture_document.verse_messages.second_try.message_3")
                };
            }
        } else {
            if (documentAttempts === 0) {
                return {
                    title: t("capture_document.front_messages.first_try.title"),
                    message1: t("capture_document.front_messages.first_try.message_1"),
                    message2: t("capture_document.front_messages.first_try.message_2"),
                    message3: t("capture_document.front_messages.first_try.message_3")
                };
            }
            if (documentAttempts === 1) {
                return {
                    title: t("capture_document.front_messages.second_try.title"),
                    message1: t("capture_document.front_messages.second_try.message_1"),
                    message2: t("capture_document.front_messages.second_try.message_2"),
                    message3: t("capture_document.front_messages.second_try.message_3")
                };
            }
            if (documentAttempts > 1) {
                return {
                    title: t("capture_document.front_messages.third_try.title"),
                    message1: t("capture_document.front_messages.third_try.message_1"),
                    message2: t("capture_document.front_messages.third_try.message_2"),
                    message3: t("capture_document.front_messages.third_try.message_3")
                };
            }
        }
        return {};
    };

    const messages = getMessages();

    return (
        <div className="container-selfie-invalid">
            {isMobile && documentAttempts < 2 && (
                <div>
                    <MobilePagination activeStep={activeStep} />
                </div>
            )}
            {documentAttempts < 2 && (
                <img className="logo" src="/client_logo.svg" alt="logo" />
            )}

            {documentAttempts <= 1 ? (
                <DocumentInstructions
                    handleCloseInstructions={handleRetryInstructions}
                    isVerse={isVerse}
                >
                    <h1 className="instruction-title-invalid">{messages.title}</h1>
                    <p>{messages.message1}</p>
                    <p>{messages.message2}</p>
                    <p>{messages.message3}</p>
                </DocumentInstructions>
            ) : documentAttempts > 1 ? (
                <DocumentSupport
                    documentBase64={documentBase64}
                    onResponse={handleRetryInstructions}
                />
            ) : null}
        </div>
    );
};

export default DocumentInvalid;