import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./welcome.sass";
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  const { t } = useTranslation(); 
  const [accepted, setAccepted] = useState(false);
  const navigate = useNavigate();

  const handleAcceptTerms = () => {
    setAccepted(!accepted);
  };

  const handleContinue = () => {
    if (accepted) {
      navigate("/signup");
    }
  }

  const initialized = useRef(false)
  useEffect(() => {

    if (!initialized.current) {

      const params = new URLSearchParams(window.location.search)
      const currentToken = params.get('token'),
      currentCompany = params.get('orc')
  
    if(currentToken){
      sessionStorage.setItem('token', `${currentToken}`)
      sessionStorage.setItem('originCompany', `${currentCompany}`)
      window.location.href = `/validating?token=${currentToken}`
      
    }
  
    initialized.current = true

  }
  }, [])


  return (
    <div className="welcome-card">
    <h1 className="bepass-title">{t("welcome_page.title")}</h1>
    <p className="bepass-text-default">{t("welcome_page.description")}</p>

    <div className="terms-container">
      <input
        type="checkbox"
        id="accept-terms"
        checked={accepted}
        onChange={handleAcceptTerms}
      />
     
      <label  className="accept-terms" htmlFor="accept-terms">
        {t("terms_message_start")} 
        <a 
          href="/bepass_terms.pdf" rel="noreferrer noopener" target="_blank"
          className="bold-link"
        >{t("bepass_privacy_biometry_terms")}</a>
      </label>
    </div>

    <button disabled={!accepted} className="continue-button"
      onClick={handleContinue}
    >
      {t("continue_button")}
    </button>
  </div>
  );
};

export default Welcome;

