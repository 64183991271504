import React, { useRef, useState } from 'react';
import './BottomTab.sass';
import {useTranslation} from "react-i18next";

interface Props {
    isPanelOpen: boolean;
    togglePanel: () => void;
    children: React.ReactNode;
}

const BottomTab = ({ isPanelOpen, children, togglePanel}:Props) => {
    const { t } = useTranslation();

    return (
        <div
            className={`instructions-panel-Bottom ${isPanelOpen ? "" : "closed"}`}
            aria-expanded={isPanelOpen}
        >
            <div
                className="lingueta"
              //   onClick={() => {
              //     document.querySelector('.instructions-content')?.scrollTo({ top: 0, behavior: 'smooth' });
              //     togglePanel()
              //   }
              // }
              onTouchEnd={() => {togglePanel();
                document.querySelector('.instructions-content')?.scrollTo({ top: 0, behavior: 'smooth' });}
              }
                role="button"
                aria-label={t("capture_face.instructions")}
                tabIndex={0}
            ></div>
            {children}
        </div>
    );
};

export default BottomTab;