import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./exemplo.sass";

const Exemplo = () => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        window.sessionStorage.setItem('language', lng);
    };

    useEffect(() => {
        const lingua = window.sessionStorage.getItem('language');
        if (lingua) {
            i18n.changeLanguage(lingua);
        }
    }, []);

    return (
        <div>
            <div className="color-table">
                <div className="column">
                    <div className="color-box primary-100">Primary 100</div>
                    <div className="color-box primary-200">Primary 200</div>
                    <div className="color-box primary-300">Primary 300</div>
                    <div className="color-box primary-400">Primary 400</div>
                    <div className="color-box primary-500">Primary 500</div>
                    <div className="color-box primary-600">Primary 600</div>
                    <div className="color-box primary-700">Primary 700</div>
                    <div className="color-box primary-800">Primary 800</div>
                    <div className="color-box primary-900">Primary 900</div>
                </div>
                <div className="column">
                    <div className="color-box secondary-100">Secondary 100</div>
                    <div className="color-box secondary-200">Secondary 200</div>
                    <div className="color-box secondary-300">Secondary 300</div>
                    <div className="color-box secondary-400">Secondary 400</div>
                    <div className="color-box secondary-500">Secondary 500</div>
                    <div className="color-box secondary-600">Secondary 600</div>
                    <div className="color-box secondary-700">Secondary 700</div>
                    <div className="color-box secondary-800">Secondary 800</div>
                    <div className="color-box secondary-900">Secondary 900</div>
                </div>
                <div className="column">
                    <div className="color-box gray-100">Gray 100</div>
                    <div className="color-box gray-200">Gray 200</div>
                    <div className="color-box gray-300">Gray 300</div>
                    <div className="color-box gray-400">Gray 400</div>
                    <div className="color-box gray-500">Gray 500</div>
                    <div className="color-box gray-600">Gray 600</div>
                    <div className="color-box gray-700">Gray 700</div>
                    <div className="color-box gray-800">Gray 800</div>
                    <div className="color-box gray-900">Gray 900</div>
                </div>
            </div>
            <div className="button-group">
                <button onClick={() => changeLanguage('en')}>{t('language.english')}</button>
                <button onClick={() => changeLanguage('uai')}>{t('language.mineires')}</button>
            </div>
        </div>
    );
};

export default Exemplo;
