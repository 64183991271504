import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from "react-i18next";
import {Detection, FaceDetector, FilesetResolver} from "@mediapipe/tasks-vision";
import RectangleFrameSvg from "../../images/FrameSvg";
import './FaceAutoCapture.sass'

interface Props {
    onPhotoTaken: (photo: string) => void,
    onError: (error: any) => void,
    isModalOpen: boolean,
    isPanelOpen?: boolean
}

const FaceAutoCapture = ({onPhotoTaken, onError, isModalOpen, isPanelOpen}: Props) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [faceDetector, setFaceDetector] = useState<FaceDetector | null>(null);
    const animationFrameRef = useRef<number | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const [isCentered, setIsCentered] = useState<boolean>(false);
    const timeoutIdRef = useRef<number | null>(null);

    const initializeFaceDetector = async () => {
        try {
            const vision = await FilesetResolver.forVisionTasks(
                "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
            );

            const detector = await FaceDetector.createFromOptions(vision, {
                baseOptions: {
                    modelAssetPath:
                        "https://storage.googleapis.com/mediapipe-models/face_detector/blaze_face_short_range/float16/1/blaze_face_short_range.tflite",
                },
                runningMode: "VIDEO",
            });

            setFaceDetector(detector);
        } catch (e) {
            console.error("Error initializing FaceDetector:", e);
        }
    };

    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: false,
                video: {facingMode: "user"},
            });

            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
        } catch (error) {
            console.error("Error starting camera:", error);
        }
    };

    const drawCanvasImage = () => {
        if (
            !(canvasRef.current &&
                videoRef.current)
        ) {
            return;
        }
        const video = videoRef.current;
        const canvas = canvasRef.current;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext("2d", {willReadFrequently: true});
        if (context) {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
        }
    }

    const getFaceDetection = () => {
        try {
            if (
                videoRef.current &&
                videoRef.current.readyState >= videoRef.current.HAVE_CURRENT_DATA
                && faceDetector
            ) {
                const startTimeMs = performance.now();
                return faceDetector.detectForVideo(
                    videoRef.current,
                    startTimeMs
                ).detections;
            }

            return [];
        } catch (error) {
            onError(error);
            throw new Error(`Error getting face detections: ${error}`);
        }
    }

    const handleCenteredFace = (faceAndScreenData: any) => {
        const tolerance = 50;

        const {
            screenCenterX,
            screenCenterY,
            faceCenterX,
            faceCenterY,
        } = faceAndScreenData;

        const isCentered =
            Math.abs(faceCenterX - screenCenterX) < tolerance &&
            Math.abs(faceCenterY - screenCenterY) < tolerance;

        setIsCentered(isCentered);
        if (isCentered) {
            if (!timeoutIdRef.current) {
                timeoutIdRef.current = window.setTimeout(captureSelfie, 3000);
            }
        } else {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
                timeoutIdRef.current = null;
            }
        }
    }

    const handleFaceDetection = (detections: Detection[]) => {
        if (
            detections.length > 0 &&
            videoRef.current &&
            videoRef.current.readyState >= videoRef.current.HAVE_CURRENT_DATA
        ) {
            const detection: Detection = detections[0];
            const boundingBox = detection.boundingBox;

            const videoWidth = videoRef.current.videoWidth;
            const videoHeight = videoRef.current.videoHeight;

            const screenCenterX = videoWidth / 2;
            const screenCenterY = videoHeight / 2;

            let faceCenterX = 0;
            let faceCenterY = 0;
            if (boundingBox) {
                faceCenterX =
                    boundingBox.originX + boundingBox.width / 2;
                faceCenterY =
                    boundingBox.originY + boundingBox.height / 2;
            }

            const faceAndScreenData = {
                screenCenterX,
                screenCenterY,
                faceCenterX,
                faceCenterY,
            }

            handleCenteredFace(faceAndScreenData);
        }
    }

    const detectFaces = () => {
        if (!videoRef.current || !faceDetector) return;

        const processFaceRecognition = () => {
            if (
                videoRef.current &&
                videoRef.current.readyState >= videoRef.current.HAVE_CURRENT_DATA
            ) {
                const detections: Detection[] = getFaceDetection();

                drawCanvasImage()

                handleFaceDetection(detections)
            }
            animationFrameRef.current = requestAnimationFrame(processFaceRecognition);
        };
        processFaceRecognition();
    };

    const captureSelfie = () => {
        if (canvasRef.current) {
            const canvas = canvasRef.current
            const base64Image = canvas.toDataURL('image/png')
            onPhotoTaken(base64Image);
        }
        stopCamera()
    }

    const stopCamera = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject as MediaStream
            const tracks = stream.getTracks()
            tracks.forEach((track) => track.stop())
        }
    }

    useEffect(() => {
        const startVideoCapturing = async () => {
            await startCamera();
            await initializeFaceDetector();
        };

        startVideoCapturing();
    }, []);

    useEffect(() => {
        if (faceDetector && !isModalOpen && !isPanelOpen) {
            detectFaces();
        }
    }, [faceDetector, isModalOpen, isPanelOpen]);

    return (
        <div className='container-auto-capture'>
            <video ref={videoRef} autoPlay playsInline className="video"/>
            <canvas ref={canvasRef} style={{display: 'none'}}/>
            <div className="clip-selfie"></div>
            <div className={`frame-selfie ${isCentered ? 'centered' : 'not-centered'}`}>
                <RectangleFrameSvg/>
            </div>
        </div>
    )
}
export default FaceAutoCapture
