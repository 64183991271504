import { useNavigate } from 'react-router-dom';
import React, {useEffect, useState} from 'react'
import BottomTab from "../Tab/BottomTab";
import {useTranslation} from "react-i18next";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './SelfieDone.sass'
import FacialInstructions from "../Intructions/FacialInstructions";
import SelfieSupport from "../SelfieSupport/SelfieSupport";
import { apiUser } from '../../basedata/apiCall';
import { BASE } from '../../basedata/constants';
import InfoModal from "../Modal/ModalInfo";

interface Props {
    selfieBase64: string,
    onResponse: (message: any) => void,
    selfieAttempts: number
}

const SelfieDone = ({selfieBase64, onResponse, selfieAttempts}: Props) => {
    const {t} = useTranslation();
    const [isPanelOpen, setIsPanelOpen] = useState(true)
    const navigate = useNavigate();
    const [loadingProgress, setLoadingProgress] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const togglePanel = () => {
        setIsPanelOpen((prev) => !prev);
    }

    //onResponse('selfieOk')

  const handleConfirm = async () => {
      console.log('let the magic begins')

      simulateLoading();

        // onResponse({
        //   status: 'success',
        //   message: 'pitch_angle'
        // });

    let _biometry:any;
    
    let _current_selfie_retries:any = Number(sessionStorage.getItem('selfie_retries') || 0)
    _current_selfie_retries += 1
    sessionStorage.setItem('selfie_retries', _current_selfie_retries)

    try{
      _biometry= await apiUser.post('/biometry/createDIS', {
        face: selfieBase64
      }) 
      _biometry = _biometry.data
    }catch(e){
      setIsModalOpen(true);
      setLoadingProgress(0);
      setError_message(`:( Tivemos um erro ao tentar processar sua foto. Vamos tentar novamente em alguns segundos?)`)
      return false
    }
    

    if(_biometry?.status !== 'success' && _current_selfie_retries > 3){
      // return onResponse({
      window.location.href = '/waiting'
      //   status: 'success',
      //   message: 'biometry_created',
      //   value: sessionStorage.getItem('token') || 'na'
      // });
    }


    if(_biometry.error === 'Face Yaw'){
      setIsModalOpen(true);
      setLoadingProgress(0);
      setError_message(`${t("capture_face.error_instructions.instructions_pitch")} ${_biometry.yaw > 0 ? 'direita':'esquerda'}. ${t("capture_face.error_instructions.instruction_look_camera")}`)
      return false
    }else if(_biometry.error === 'Face Pitch'){
      setIsModalOpen(true);
      setLoadingProgress(0);
      setError_message(`${t("capture_face.error_instructions.instructions_pitch")} ${_biometry.pitch > 0 ? 'cima':'baixo'}. ${t("capture_face.error_instructions.instruction_look_camera")}`)
      return false
    }else if(_biometry.error === 'Glassess detected'){
      setIsModalOpen(true);
      setLoadingProgress(0);
      setError_message(`${t("capture_face.error_instructions.instructions_error_glasses")}`)
      return false
    }else if(_biometry.error === 'Mask detected'){
      setIsModalOpen(true);
      setLoadingProgress(0);
      setError_message(`${t("capture_face.error_instructions.instructions_error_mask")}`)
      return false
    }else if(
      _biometry.error === 'Cant check liveness quality'
      || _biometry.error === 'Cant create Face'
      || _biometry.error === 'Face bad accuracy'
      || _biometry.error === 'Cant check face quality'
    ){
      setIsModalOpen(true);
      setLoadingProgress(0);
      setError_message(`${t("capture_face.error_instructions.instructions_error_generic_face")}`)
      return false
    }else if (_biometry.error){
      setIsModalOpen(true);
      setLoadingProgress(0);
      setError_message(`${t("capture_face.error_instructions.instructions_error_generic_face")}`)
      return false
    }

    
    if (_biometry.dis < BASE.company.dis) {
      setIsModalOpen(true);
      setLoadingProgress(0);
      setError_message(`${t("capture_face.error_instructions.instructions_error_face_quality")}`)
      return false
    }

    const croppedFace = _biometry.FACEcrop.data
      
    let _save_biometry:any = await apiUser.post('/biometry/create', {
      originToken: sessionStorage.getItem('token') || 'na',
      photoURL: 'zouan',
      partnerName: 'bepass_new_face',
      imageBase64: selfieBase64,
      imageBase64cropped: croppedFace,
      originCompany: BASE.company.id,
      originCompanyId: BASE.company.id,
    })

    _save_biometry = _save_biometry.data
    setIsLoading(false);
    setLoadingProgress(0);

      
    if (_save_biometry.status === 'userNotFound') {

      if(_current_selfie_retries > 3){
        // return onResponse({
        window.location.href = '/waiting'
        //   status: 'success',
        //   message: 'biometry_created',
        //   value: sessionStorage.getItem('token') || 'na'
        // });
      }
      
      return onResponse({
        status: 'error',
        message: 'user_not_found',
        value: sessionStorage.getItem('token') || 'na'
      });

    }

    if(_save_biometry?.status === 'success'){
      return onResponse({
        status: 'success',
        message: 'biometry_created',
        value: sessionStorage.getItem('token') || 'na'
      });
    }      
    }

    const handleRecapture = () => {
      // setIsModalOpen(false)
      // onResponse('selfieCancel');
      window.location.reload()
    }

    const simulateLoading = () => {
        setIsLoading(true);
        setLoadingProgress(0);
        const interval = setInterval(() => {
            setLoadingProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(interval);
                }
                return prev + 1;
            });
        }, 900);
    };

    useEffect(() => {
      const params = new URLSearchParams(window.location.search)

      const currentToken:any = params.get('token');
      const currentOrc:any = params.get('orc');

      if(currentToken){
        sessionStorage.setItem('token', currentToken )
        sessionStorage.setItem('orc', currentOrc )  
      }
  
    }, [])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error_message, setError_message] = useState('A sua selfie e a foto do documento não batem.');
    const closeModal = () => {
      handleRecapture()
      setIsModalOpen(false);
    };


    return (
        <div className="container-selfie-done">
           <InfoModal isOpen={isModalOpen} onClose={closeModal}>
                <h1>Opa!</h1>
                <p>{error_message}</p>
                <button className='button-retry-action' onClick={() => handleRecapture()}>{t("success_document.try_again")}</button>
            </InfoModal>
            <img src={selfieBase64} alt="selfie" className="selfie-taken"/>
            {isLoading ? (
                <div className="loader-container">
                    <div
                        className="loader-bar"
                        style={{width: `${loadingProgress}%`}}
                    ></div>
                    <span className="loader-percentage">{loadingProgress}%</span>
                </div>
            ) : (
                <BottomTab
                    togglePanel={togglePanel}
                    isPanelOpen={isPanelOpen}
                >
                    <div className="selfie-confirmation-content">
                        <div className="selfie-confirmation-text">
                            {/* {selfieAttempts === 0 ? (
                                <h1>{t("well_done_selfie.title_1")}</h1>
                            ) : selfieAttempts === 1 ? (
                                <h1>{t("well_done_selfie.title_2")}</h1>
                            ) : selfieAttempts > 1 ? (
                                <h1>{t("well_done_selfie.title_3")}</h1>
                            ) : null
                            } */}
                            <p>{t("well_done_selfie.quote")}</p>
                        </div>
                        <div className="selfie-confirmation-buttons">
                            <div className='try-again-section'>
                                <button onClick={handleConfirm}>{t("well_done_selfie.send_photo")}</button>
                                <div className='try-again-link' onClick={handleRecapture}>
                                    <ArrowBackIcon/>
                                    <p>{t("well_done_selfie.try_again")}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </BottomTab>
            )}
        </div>
    )
}
export default SelfieDone;