import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SuccessHandler.sass";
import help_image from "../images/Illustration-help.png";
import { BASE } from "../basedata/constants";

const MessageErrorHandler = () => {
  const { t } = useTranslation(); 
  const [accepted, setAccepted] = useState(false);



  useEffect(() => {
    const _temp_language = sessionStorage.getItem('language') || 'pt'
    sessionStorage.clear()
    sessionStorage.setItem('success_flow', 'true')
    sessionStorage.setItem('language', _temp_language)
  }, []);


  return (
    <div className="success-container">
      <div className="progress-indicator">
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index} className="progress-bar"></div>
        ))}
      </div>
      <div className="left-side"></div> 
      <div className="right-side">
        <div className="success-card">
          <img className="logo" src={BASE.company.logo} alt={BASE.company.name} />
          <h1 className="success_title">
            {t("support_message.title")}
          </h1>
          <div className="success-message-container">
            <p className="success-message">
            {t("support_message.support_quote")}
            </p>
            <p className="success-message">
            {t("support_message.support_contact_label")}<br/>
            <strong>{t("signup.labels.email")}:</strong> biometria@corinthians.com.br<br/>
            {/* <strong>{t("support_message.telephone_key")}:</strong> 21 3512 1212 */}
            </p>
            {/* <img src={help_image} alt="Sucesso!" className="no-svg-tip" /> */}
            <p className="success-message">
            {t("support_message.support_contact_text")}<br/>
            </p>
          </div>
        </div>
      </div>
      {/* <footer className="success-footer">
        <div className="success-footer-logo"></div>
      </footer> */}
    </div>
  );
};

export default MessageErrorHandler;