import React, {useEffect, useState} from 'react'
import ModalInfo from "../Modal/ModalInfo";
import {useTranslation} from "react-i18next";
import './DocumentSuccess.sass'
import { Navigate, useNavigate } from 'react-router-dom';

import document from "../../images/document_svg.svg";

interface Props {
    documentBase64: string
}

const DocumentSuccess = ({documentBase64}:Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [loadingProgress, setLoadingProgress] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const closeModal = () => {
        setIsSuccessModalOpen(false);
    };

    const handleSendDocument = () => {
        navigate("/mobile-document");
    }

    const simulateLoading = () => {
        setIsLoading(true);
        setLoadingProgress(0);
        const interval = setInterval(() => {
            setLoadingProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(interval);
                    // setIsLoading(false);
                    // setIsSuccessModalOpen(true)
                }
                return prev + 1;
            });
        }, 900);
    };


    useEffect(() => {
        simulateLoading();
    },[])

    return (
        <div className="container-selfie-success">
            <img src={documentBase64} alt="selfie" className="selfie-taken"/>
            {isLoading && (
                <div className="loader-container">
                    <div
                        className="loader-bar"
                        style={{width: `${loadingProgress}%`}}
                    ></div>
                    <span className="loader-percentage">{loadingProgress}%</span>
                </div>
            )}
            <ModalInfo isOpen={isSuccessModalOpen} onClose={closeModal}>
                <img src="/document_svg.svg" alt="icone documento"/>
                <h1>{t("success_document.well_done")}</h1>
                <p>{t("success_document.quote_1")}</p>
                <p>{t("success_document.quote_2")}</p>
                <button className="btn_send_document" onClick={handleSendDocument}>
                    <img src={document} alt="icone documento"/>
                    <span>{t("success_document.send_document")}</span>
                </button>
            </ModalInfo>
        </div>
    )
}
export default DocumentSuccess
