import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./CaptureDocumentMobile.sass";
import DocumentCapture from "../CaptureDocument/DocumentCapture";
import DocumentDone from "../DocumentDone/DocumentDone";
import DocumentInstructions from "../Intructions/DocumentInstructions";
import { useNavigate } from "react-router-dom";
import DocumentInvalid from "../DocumentInvalid/DocumentInvalid";
import DocumentSuccess from "../DocumentSuccess/DocumentSuccess";
import InfoModal from "../Modal/ModalInfo";
import BottomTab from "../Tab/BottomTab";

const CaptureDocumentMobile = ({ isVerse = false }: { isVerse?: boolean }) => {
    const { t } = useTranslation();
    const [documentBase64, setDocumentBase64] = useState<string | null>(null);
    const [isCameraOpen, setIsCameraOpen] = useState(true);
    const [capturedImage, setCapturedImage] = useState<string | null>(null);
    const [isInstructionsOpen, setIsInstructionsOpen] = useState(false);
    const [userState, setUserState] = useState<string>('document');
    const [documentAttempts, setDocumentAttempts] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isDocumentVerseTime, setIsDocumentVerseTime] = useState(isVerse);

    const navigate = useNavigate();

    const handleDocumentCapture = (response: { document: string }) => {
        setCapturedImage(response.document);
        setIsCameraOpen(false);
        setIsInstructionsOpen(false); 
        setDocumentBase64(response.document);
    };

    const reCapturePhoto = () => {
        setDocumentBase64(null);
        setIsCameraOpen(true);
        setIsInstructionsOpen(true);
    };

    const handleUserResponse = (message: string) => {

      if(message === 'retry_back'){
        setIsDocumentVerseTime(true)
        setDocumentBase64(null);
        setIsCameraOpen(true);
        setIsInstructionsOpen(false);
        
        setTimeout(() => {
          setIsModalOpen(true);
        }, 1000);
        return false
      }

      console.log('mylifetime:', isDocumentVerseTime)
      if(isDocumentVerseTime){
        return window.location.href = '/success'
      }

      setIsDocumentVerseTime(true)
      setDocumentBase64(null);
      setIsCameraOpen(true);
      setIsInstructionsOpen(false);
      
      setTimeout(() => {
        setIsModalOpen(true);
      }, 1000);
        // if (message === 'documentOk') {
        //     setUserState('documentDone');
        // } else if (message === 'documentInvalid') {
        //     setUserState('documentInvalid');
        // } else {
        //     reCapturePhoto();
        // }
    };

    const handleRetryPhoto = () => {
        setCapturedImage(null);
        setIsCameraOpen(true);
        setIsInstructionsOpen(true);
    };

    const toggleInstructions = () => {
      setIsPanelOpen((prev) => !prev);
    };

    const handleRetry = () => {
        setDocumentAttempts((prevAttempts) => prevAttempts + 1);
        setUserState('document');
        reCapturePhoto();
    };

    const handleError = (error: Error) => {
        console.error(error);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
      const params = new URLSearchParams(window.location.search)

      const has_document_step:any = params.get('step');

      if(has_document_step === 'document_back'){
        setIsDocumentVerseTime(true)
      }
  
  }, []);

    useEffect(() => {
        setIsCameraOpen(true);
        setCapturedImage(null);
        setIsInstructionsOpen(false);
        setUserState("document");
        setDocumentAttempts(0);
    }, [isDocumentVerseTime]);

    useEffect(() => {

      const _has_history = sessionStorage.getItem('user_history')
      const _success_flow = sessionStorage.getItem('success_flow')
      if(_has_history === 'success' || 
        _success_flow === 'true'
      ){
        window.location.href = '/'
      }
      if(_has_history === 'document_back'){
        setIsDocumentVerseTime(true)
        setIsModalOpen(true)

        // setTimeout(() => {
        //   setIsModalOpen(false)
        // }, 8000);
      }else{
        setIsPanelOpen(true)
      }


  }, []);

    const [isPanelOpen, setIsPanelOpen] = useState(false)
    const togglePanel = () => {
      setIsPanelOpen((prev) => !prev);
    };
    return (
        <div className="mobile-capture-container">
           <InfoModal isOpen={isModalOpen} onClose={closeModal}>
                <h1>
                    {isDocumentVerseTime
                        ? t("modal_document_intructions.title_back")
                        : t("modal_document_intructions.title_front")}
                </h1>
                <p>
                    {isDocumentVerseTime
                        ? t("modal_document_intructions.quote_back")
                        : t("modal_document_intructions.quote_front")}
                </p>
                <button className="retry-button inside-modal" onClick={() => {closeModal()}
                }>
                   Próximo
                </button>
            </InfoModal>
            {!documentBase64 ? (
                <div className={`camera-container ${isModalOpen ? "blur-background" : ""}`}>
                    <DocumentCapture
                        onResponse={handleDocumentCapture}
                        onError={console.error}
                        isCameraOpen={isCameraOpen}
                        isInstructionsOpen={isInstructionsOpen}
                    />
                </div>
            ) : 
                userState === 'document' && documentBase64 ? (
                    <DocumentDone
                        documentBase64={documentBase64}
                        onResponse={handleUserResponse}
                        documentAttempts={documentAttempts}
                        isVerse={isDocumentVerseTime}
                    />
                ) : userState === 'documentDone' && documentBase64 ? (
                    <DocumentSuccess documentBase64={documentBase64} />
                ) : userState === "documentInvalid" && documentBase64 ? (
                    <DocumentInvalid
                        documentAttempts={documentAttempts}
                        handleRetryInstructions={handleRetry}
                        documentBase64={documentBase64}
                        isVerse={isDocumentVerseTime}
                    />
                ) : null
            }
            {isCameraOpen && !documentBase64 && ( 

              <BottomTab
              togglePanel={togglePanel}
              isPanelOpen={isPanelOpen}
              >
              <DocumentInstructions
                  handleCloseInstructions={toggleInstructions}
                  isVerse={isDocumentVerseTime}
              >
              <h1 className="instruction-title text-center">{t("capture_face.title")}</h1>
              <p>{t("capture_document.instructions")}</p>
              </DocumentInstructions>
              </BottomTab>
            )}
        </div>
    );
};

export default CaptureDocumentMobile;