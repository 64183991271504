import React from 'react';
import '../../basedata/variables.sass'
import './MobilePagination.sass'

interface MobilePaginationProps {
    activeStep: number;
}

const MobilePagination: React.FC<MobilePaginationProps> = ({ activeStep }) => {
    return (
        <div className="mobile-pagination">
            <div className="progress-indicator">
                {Array.from({length: 6}).map((_, index) => (
                    <div key={index} className={`progress-bar ${index <= activeStep ? 'set-green':'set-gray'}`}></div>
                ))}
            </div>
        </div>
    );
};

export default MobilePagination;